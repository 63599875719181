.payment-slip-uploader {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 4px dotted #1D8DDC !important;
  padding: 10px;
}

.payment-slip-uploader .ant-upload-select {
  width: 100%;
  height: 100%;
}

.bank-account-checkbox-group label.ant-radio-wrapper span.ant-radio {
  display: none;
}

.bank-account-checkbox-group label.ant-radio-wrapper span.ant-radio+span {
  display: inline-block;
  width: 100%;
  padding: 0;
}

.bank-account-checkbox-group label.ant-radio-wrapper span.ant-radio-checked+span>div {
  border: 3px solid #1677ff;
}
