/* @import '~roboto-fontface/css/roboto/roboto-fontface.css'; */
@import url('./preflight.css');
/* @tailwind base; */
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif !important;
}

.organization .anticon-down {
  color: #737898;
  margin-top: 12px !important;
  font-size: 14px;

}

.depart .ant-select-selector {
  padding: 8px !important;
}

.datepicker .ant-picker {
  border-radius: 2px !important;
  border: none !important;

}

.daterangepicker .ant-picker {
  border-radius: 2px !important;
  border: none !important;
  width: 245px;
}

.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: none !important
}


.datepicker .ant-picker-input input {
  color: #263238 !important;
  font-size: 15px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  padding: 0;
}

.daterangepicker .ant-picker-input input {
  color: #263238 !important;
  font-size: 15px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  padding: 0;
}




.datepicker .ant-picker-focused {
  box-shadow: none;
}

.daterangepicker .ant-picker-focused {
  box-shadow: none;
}


.dashboard .ant-tabs-nav-list {
  background-color: white;

  border-radius: 5px;
}

.dashboard .ant-tabs-tab-active {
  border: none !important;
}

.dashboard .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  background: white;
  border: none !important;
  color: black !important;
  border-radius: 5px;
  padding: 10px 20px;
}

.dashboard .ant-tabs-tab {
  background-color: white !important;
  border: none !important;
  padding: 5px !important;
}

.daterangepicker .ant-picker-range-separator {
  color: #263238 !important;
  font-size: 15px;
  margin-top: -2px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.dashboard .ant-tabs-nav-list {
  font-family: 'Poppins', sans-serif;

}

.ant-table-content {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}


.invite .ant-select-selector {
  border-radius: 0px !important;
}

.department .ant-select-selection-placeholder {
  font-weight: 500 !important;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
}

.main .ant-select-selector {
  border-radius: 3px !important;

}

.ant-drawer-body {
  padding: 15px !important;
}

.avatar .ant-avatar-circle {
  color: white !important;
  background-color: #5534A5 !important;
}

.screenshot .ant-input-affix-wrapper {
  border-radius: 5px !important;
}

.invite .ant-select-selection-placeholder {
  font-weight: 500 !important;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.invite .ant-select-selection-item-content {
  padding: 0px 6px !important;

}

.meeting .ant-select-selection-item-content {
  padding: 0px 6px !important;
  font-family: 'Poppins', sans-serif;
}

.meeting .ant-select-selection-placeholder {
  font-weight: 500 !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.meeting .ant-select-selection-item-content {
  padding: 0px 6px !important;
}

.multi .ant-select-selection-item:nth-of-type(even) {
  background-color: #F0F0F0 !important;
  border: 1px solid #E0E0E0 !important;
  border-left: 3px solid #3376FF !important;
  margin: 5px !important;
  border-radius: 4px !important;
}

.timesheet .ant-table-thead>tr>th {
  /* Your styles here */
  padding: 5px;
  /* Example styles, replace with your own */
}

.multi .ant-select-selection-item:nth-of-type(odd) {
  background-color: white !important;
  border: 1px solid #E0E0E0 !important;
  border-left: 3px solid #3376FF !important;
  margin: 5px !important;
  border-radius: 4px !important;
}

.team .ant-table-content {
  text-align: center;
}

.dashboard .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
  background: #3376FF;
  border: none !important;
  color: white !important;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;

}

.poppins-font {
  font-family: 'Poppins', sans-serif;
}

.dashboard-link:hover .filter-blue {
  filter: invert(40%) sepia(60%) saturate(4496%) hue-rotate(212deg) brightness(102%) contrast(101%);
}

.filter-white {
  filter: brightness(0) invert(1) saturate(100%) brightness(100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.realtime .ant-modal-content {
  height: 450px;
  overflow-y: auto;
}


.payment-table .ant-form-item-explain-error {
  font-family: "Poppins";
  font-size: 13px;
  margin: 5px 0;
}

.payment-table .ant-tag {
  border: none !important;
}

.payment-table .ant-table-cell {
  color: #455A64;
}

.payment-table th.ant-table-cell {
  background-color: white !important;
}

.ant-input::placeholder {
  font-family: 'Poppins';
}

.password-strength-checker>div div {
  height: 14px !important;
  border-radius: 2px;
}

.password-strength-checker {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.password-strength-checker>div {
  flex: 1 1 auto;
}

.password-strength-checker>div div:nth-child(6),
.password-strength-checker>div div:nth-child(7) {
  display: none !important;
}

.password-strength-checker>p {
  width: 120px !important;
  @apply font-poppins text-dark font-medium
}

.sf-new-org-hr::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 50%;
  z-index: -1;
}

.organization-selector .ant-dropdown-menu-item {
  padding: 0 !important;
}

.time-summary .apexcharts-toolbar {
  display: none !important;
}

.time-summary text {
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
}

.organization-selector ul li {
  margin-bottom: 5px !important
}


.plan-feature-list::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.plan-feature-list {
  scrollbar-width: none;
}

.plan-feature-list {
  -ms-overflow-style: none;
}

/* Get Started */
.sf-get-started-loading::after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0% {
    content: '';
  }

  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }

  100% {
    content: '';
  }
}



.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}


.ant-picker-week-panel .ant-picker-content th[aria-label="empty cell"] {
  display: none;
}
.ant-picker-week-panel .ant-picker-content .ant-picker-week-panel-row .ant-picker-cell.ant-picker-cell-week {
  display: none;
}